import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Journey from './Journey';
import SimpleAccordion from '../../components/Accordions/SimpleAccordion';

const testJourneys = [
  { name: 'Learn to code a game', id: 'test1', status: 'On Track' },
  { name: 'Lose 25lb in 4 months', id: 'test2', status: 'On Track' },
  { name: 'Write 3 songs in 4 weeks', id: 'test3', status: 'Off Track' },
];

export default function Journeys() {
  const [fakeConfig, setFakeConfig] = useState({
    firstJourney: false,
  });
  return (
    <Box sx={{ my: 3 }}>
      <Typography sx={{ mb: 2 }} variant='h5' component='h2'>
        My Journeys
      </Typography>
      { fakeConfig.firstJourney && (
        <Box>
          <Typography variant='h6'>Plan your first journey</Typography>
          <Typography>What is a journey?</Typography>
          <Typography>
            Life. Ok, but really, a journey in YWG is all about setting SMART
            goals.
          </Typography>
          <Alert severity='info'>
            <AlertTitle>Make it SMART</AlertTitle>
            <p>S = Specific</p>
            <p>M = Measurable</p>
            <p>A = Attainable</p>
            <p>R = Rewarding</p>
            <p>T = Time-bound</p>
          </Alert>
          <Typography>
            You are going to create a powerful, productivity flywheel effect
            when you act and reflect. Now, set yourself up for success with a
            solid plan that can easily adapt to change, and prioritize as you
            go.
          </Typography>
        </Box>
      )}
      {testJourneys.map((journey) => (
        <SimpleAccordion
          id={journey.id}
          name={journey.name}
          status={journey.status}
          key={journey.id}
        >
          <Journey name={journey.name} />
        </SimpleAccordion>
      ))}
    </Box>
  );
}