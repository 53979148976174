import React, {
  useContext,
  useReducer,
  useMemo,
  createContext,
} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

let CustomThemeContext = createContext();

function CustomThemeProvider({ children }) {
  const lightTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#36b0f2',
            contact: '#0F044C',
            role: '#0F044C',
            action: '#4C0027',
            note: '#1E5128',
          },
          error: {
            main: '#f236b0',
          },
          warning: {
            main: '#7836f2',
          },
          info: {
            main: '#3652f2',
          },
          success: {
            main: '#36f2d6',
          },
          paper: {
            canvasColor: '#fff',
          },
        },
        typography: {
          h1: {
            fontSize: '5rem',
          },
        },
      }),
    []
  );

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#36b0f2',
            contact: '#0F044C',
            role: '#0F044C',
            action: '#4C0027',
            note: '#1E5128',
          },
          error: {
            main: '#f236b0',
          },
          warning: {
            main: '#7836f2',
          },
          info: {
            main: '#3652f2',
          },
          success: {
            main: '#36f2d6',
          },
          paper: {
            canvasColor: '#fff',
          },
        },
        typography: {
          h1: {
            fontSize: '5rem',
          },
        },
      }),
    []
  );

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const initialState = { theme: prefersDarkMode ? darkTheme : lightTheme };
  const [themeState, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case 'toggleTheme':
        const selectedTheme = action.requestedTheme === 'dark' ? darkTheme : lightTheme;
        state = { theme: selectedTheme };
        break;
      case 'systemTheme':
        state = { theme: prefersDarkMode ? darkTheme : lightTheme }
        break;
      default:
        state = initialState
    }
    return state;
  }

  const { theme } = themeState

  let value = { theme, dispatch, prefersDarkMode }

  return (
    <CustomThemeContext.Provider value={value}>
      <CustomThemeContext.Consumer>
        {({ theme }) => (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        )}
      </CustomThemeContext.Consumer>
    </CustomThemeContext.Provider>
  );
}

function useCustomTheme() {
  return useContext(CustomThemeContext);
}

export { CustomThemeProvider, useCustomTheme };
