import React from 'react';

import FormAutoSaveAction from '../Models/Action/FormAutoSaveAction';
import FormAutoSaveContact from '../Models/Contact/FormAutoSaveContact';
import FormAutoSaveNote from '../Models/Note/FormAutoSaveNote';
import FormAutoSaveRole from '../Models/Role/FormAutoSaveRole';

export default function FormFields({ item, collection }) {
    return (
      <>
        {collection === 'roles' ? (
          <FormAutoSaveRole item={item} collection={collection} />
        ) : collection === 'actions' ? (
          <FormAutoSaveAction item={item} collection={collection} />
        ) : collection === 'notes' ? (
          <FormAutoSaveNote item={item} collection={collection} />
        ) : collection === 'contacts' ? (
          <FormAutoSaveContact item={item} collection={collection} />
        ) : (
          `DON'T HAVE THAT MODEL YET!`
        )}
      </>
    );
  };