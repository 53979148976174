export {
    sort
}

/**
 * 
 * @param {*} array 
 * @param {*} sortField 
 * @returns sorted array
 */
function sort(array, sortField, asc = false) {
    if (array?.length > 0) {
        if (asc) {
            return array.sort((a, b) => a.data[sortField] - b.data[sortField]) // asc
        } else {
            return array.sort((a, b) => b.data[sortField] - a.data[sortField]) // desc
        }
    }
    return []
}