import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '../../Cards/Card';
import TimelineSeparator from './Components/TimelineSeparator'
import TimelineInfo from './Components/TimelineInfo'

export default function Timeline({ items, theme }) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <TimelineInfo theme={theme}></TimelineInfo>
      {items?.length > 0 ? (
        items.map((item) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={item.id}>
            <Card item={item} collection={item.data.collection} theme={theme}></Card>
            <TimelineSeparator theme={theme}></TimelineSeparator>
          </Box>
        ))
      ) : items?.length === 0 || !items ? (
        <p>No items yet. Add an item.</p>
      ) : (
        <p>Loading...</p>
      )}
    </Box>
  );
}