import React, {
  useEffect,
  useContext,
  createContext,
  useReducer,
  useCallback,
} from 'react';

import { useAuth } from './auth';
import {
  db,
  COLLECTIONS,
  query,
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  where,
  endBefore,
  startAt,
} from '../firebase';
import { sort } from '../helpers/sorters';

let DataContext = createContext();

function DataProvider({ children }) {
  const initialState = {
    collections: {},
    listeners: [],
    start: {},
    end: {},
    lastVisibleHistory: [],
    loadCount: {},
  };
  const [dataState, dispatch] = useReducer(reducer, initialState);

  const { user } = useAuth();

  // inspiration: https://medium.com/@650egor/firestore-reactive-pagination-db3afb0bf42e
  // solve! https://stackoverflow.com/questions/71036690/when-should-i-unsubscribe-from-firestore-listener
  // the idea with this is to be able to listening now from the earliest doc of our first query forward from now on
  // so of the last limit from first query we want to listen to all of them, plus any new ones that come in.
  // then create multiple listeners for each set of retrieved docs

  const subscribeItems = useCallback(
    async (collectionName, itemLimit = 10) => {
      const queryForStart = query(
        collection(db, collectionName),
        where('owner', '==', user?.uid ?? ''),
        orderBy('createdAt', 'desc'),
        limit(itemLimit)
      );
      const startRefSnapshot = await getDocs(queryForStart);

      let queryForListener;

      if (startRefSnapshot.docs?.length > 0) {
        const start = startRefSnapshot.docs[startRefSnapshot.docs.length - 1];
        // listen forward from the furthest back of the most recent first messages I want
        // that's why there's no limit on this and order ascending
        queryForListener = query(
          collection(db, collectionName),
          where('owner', '==', user?.uid ?? ''),
          orderBy('createdAt'),
          startAt(start)
        );
      } else {
        queryForListener = query(
          collection(db, collectionName),
          where('owner', '==', user?.uid ?? ''),
          orderBy('createdAt')
        );
      }
      // create listener using startAt snapshot (starting boundary)
      let unsubscribe = onSnapshot(queryForListener, {
        next: (snapshot) => {
          const changes = [];
          const addedOrModified = [];
          snapshot.docChanges().forEach((change) => {
            changes.push({
              data: change.doc.data(),
              id: change.doc.id,
              type: change.type,
            });

            if (change.type === 'added' || change.type === 'modified') {
              addedOrModified.push(change.doc);
            }
          });

          // move the cursor
          if (addedOrModified?.length > 0) {
            let lastVisible = addedOrModified[0];
            dispatch({
              type: 'setStart',
              start: lastVisible,
              collection: collectionName,
              loadCount: addedOrModified.length,
            });
          }
          dispatch({
            type: 'setItems',
            changes,
            collection: collectionName,
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
      dispatch({
        type: 'addListener',
        listener: unsubscribe,
        collection: collectionName,
      });

      return unsubscribe;
    },
    [user?.uid]
  );

  const subscribeMoreItems = useCallback(
    async (collectionName, itemLimit = 10) => {
      const queryForStart = query(
        collection(db, collectionName),
        where('owner', '==', user?.uid ?? ''),
        orderBy('createdAt', 'desc'),
        startAt(dataState.start[collectionName]),
        limit(itemLimit)
      );
      const startRefSnapshot = await getDocs(queryForStart);
      if (startRefSnapshot.docs?.length > 0) {
        let end = dataState.start[collectionName];
        let start = startRefSnapshot.docs[startRefSnapshot.docs.length - 1];
        // create another listener using new boundaries
        let queryForListener = query(
          collection(db, collectionName),
          where('owner', '==', user?.uid ?? ''),
          orderBy('createdAt'),
          startAt(start), // start at the earliest doc in the one time query
          endBefore(end), // end before the previous start
          limit(itemLimit)
        );

        // create listener using startAt snapshot (starting boundary)
        let unsubscribe = onSnapshot(queryForListener, {
          next: (snapshot) => {
            const changes = [];
            const addedOrModified = [];
            snapshot.docChanges().forEach((change) => {
              changes.push({
                data: change.doc.data(),
                id: change.doc.id,
                type: change.type,
              });

              if (change.type === 'added' || change.type === 'modified') {
                addedOrModified.push(change.doc);
              }
            });

            // move the cursor
            if (addedOrModified?.length > 0) {
              let lastVisible = addedOrModified[0];
              dispatch({
                type: 'setStart',
                start: lastVisible,
                collection: collectionName,
                loadCount: addedOrModified.length,
              });
            }
            dispatch({
              type: 'setItems',
              changes,
              collection: collectionName,
            });
          },
          error: (error) => {
            console.error(error);
          },
        });

        return unsubscribe;
      }
    },
    [user?.uid, dataState.start]
  );

  useEffect(() => {
    // dispatch({ type: 'unsubscribeAll' }); // need this because sometimes there are left over subs for some reason
    COLLECTIONS.forEach((col) => subscribeItems(col));

    return () => dispatch({ type: 'unsubscribeAll' });
  }, [subscribeItems]);

  function reducer(state, action) {
    switch (action.type) {
      case 'addListener':
        state = {
          ...state,
          listeners: [
            ...state.listeners,
            { [action.collection]: action.listener },
          ],
        };
        break;
      case 'setStart':
        let keyExists = state.loadCount.hasOwnProperty(action.collection);
        let loadCount = state.loadCount[action.collection];
        state = {
          ...state,
          start: { ...state.start, [action.collection]: action.start },
          lastVisibleHistory: [
            ...state.lastVisibleHistory,
            { start: action.start, collection: action.collection },
          ],
          loadCount: {
            ...state.loadCount,
            [action.collection]: keyExists
              ? (loadCount += action.loadCount)
              : action.loadCount,
          },
        };
        break;
      case 'setItems':
        const added = action.changes.filter(
          (change) => change.type === 'added'
        );
        const modified = action.changes.filter(
          (change) => change.type === 'modified'
        );
        const removed = action.changes.filter(
          (change) => change.type === 'removed'
        );

        const original = state.collections[action.collection];

        let items;
        if (original?.length > 0) {
          // get rid of removed and update modified
          const modifiedIds = modified.map((change) => change.id);
          const removedIds = removed.map((change) => change.id);
          const takeoutRemoved = original.filter(
            (change) => !removedIds.includes(change.id)
          );
          const takeoutModified = takeoutRemoved.filter(
            (change) => !modifiedIds.includes(change.id)
          );

          items = [...takeoutModified, ...modified, ...added];
        } else {
          items = added;
        }

        state = {
          ...state,
          collections: {
            ...state.collections,
            [action.collection]: sort(items, 'createdAt'),
          },
        };
        break;
      case 'unsubscribe':
        if (state.listeners?.length > 0 && state.listeners[action.collection]) {
          const requestedUnsub = state.listeners[action.collection];
          requestedUnsub();
          delete state.listeners[action.collection]; // remove the listener from the list
        }
        break;
      case 'unsubscribeAll':
        state.listeners.forEach((listener) => {
          const l = Object.values(listener);
          const unsub = l[0];
          unsub();
        });
        state = initialState; // reset all state, removes listeners from the list and collections, etc.
        break;
      default:
    }
    return state;
  }

  let value = { dataState, dispatch, subscribeItems, subscribeMoreItems };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

function useData() {
  return useContext(DataContext);
}

export { DataProvider, useData };
