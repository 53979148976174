import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import App from './App';
import Home from './routes/Home'
import Act from './routes/Act';
import Reflect from './routes/Reflect';
import Plan from './routes/Plan'
import Signup from './routes/Signup'
import SignInSide from './routes/Login';
import PasswordReset from './routes/PasswordReset';

import reportWebVitals from './reportWebVitals';
import { handleSignup, handlePasswordReset } from './utils/firebase';

import { AuthProvider, RequireAuth } from './utils/providers/auth';
import { SnackbarProvider } from './utils/providers/snackbar';
import { DataProvider } from './utils/providers/data';
import { CustomThemeProvider } from './utils/providers/theme';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      <CustomThemeProvider>
        <AuthProvider>
          <DataProvider>
            <SnackbarProvider>
              <Routes>
                <Route path='/' element={ <App/> }>
                  <Route path='/' element={ <Home/> }>
                      {/* <Route path='/' element={<Navigate to='act'/>}></Route> */}
                      <Route path='act' element={<RequireAuth><Act/></RequireAuth>} />
                      <Route path='reflect' element={<RequireAuth><Reflect/></RequireAuth>} />
                      <Route path='plan' element={<RequireAuth><Plan/></RequireAuth>} />
                  </Route>
                  <Route path='signup' element={ <Signup handleSignup={handleSignup}/> }/>
                  <Route path='login' element={ <SignInSide /> }/>
                  <Route path='reset-password' element={ <PasswordReset handlePasswordReset={handlePasswordReset}/> }/>
                </Route>
              </Routes>
            </SnackbarProvider>
          </DataProvider>
        </AuthProvider>
      </CustomThemeProvider>
    </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();