import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://yearwego.com/">
        YearWeGo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp({ handleSignup }) {
  const [showError, setShowError] = useState(false)

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }

  const { handleSubmit, reset, setValue, control } = useForm({ defaultValues });
  let navigate = useNavigate()

  const showErrorFn = () => {
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 5000)
  }

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      const { email, password, firstName, lastName } = data
      await handleSignup(email, password, firstName, lastName)
      navigate('/')
    } catch (error) {
      showErrorFn()
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='firstName'
                control={control}
                defaultValue=''
                required={{ required: 'First name required' }}
                render={({ field: { onChange, value }}) => {
                  return (
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='lastName'
                control={control}
                defaultValue=''
                required={{ required: 'Last name required' }}
                render={({ field: { onChange, value }}) => {
                  return (
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                required={{ required: 'Email required' }}
                render={({ field: { onChange, value }}) => {
                  return (
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='password'
                control={control}
                defaultValue=''
                required={{ required: 'Password required'}}
                render={({ field: { onChange, value }}) => {
                  return (
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              />
            </Grid>
            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        { showError && <Alert severity='error'>Please correct errors in the form.</Alert> }
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}