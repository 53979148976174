import React, { useContext, createContext, useReducer } from 'react';

let SnackbarContext = createContext();

function SnackbarProvider({ children }) {
  const initialState = { open: false, message: '', id: '' };
  const [snackbarState, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    let newState;
    let defaultOpen = { open: true, message: action.message, id: action.id };
    switch (action.type) {
      case 'close':
        newState = { open: false, message: '', id: '' };
        break;
      case 'open':
        newState = { open: true, message: '', id: '' };
        break;
      case 'add':
      case 'update':
        newState = {
          ...defaultOpen,
          severity: 'success',
        };
        break;
      case 'delete':
        newState = {
          ...defaultOpen,
          severity: 'warning',
        };
        break;
      case 'firebaseError':
        newState = {
          ...defaultOpen,
          severity: 'error',
        };
        break;
      default:
        // console.log(action.type);
    }
    return newState;
  }

  const handleSnackbarClick = () => {
    return dispatch('open');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    return dispatch('close');
  };

  let value = { snackbarState, dispatch, handleSnackbarClick, handleSnackbarClose };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}

function useSnackbar() {
  return useContext(SnackbarContext);
}

export { SnackbarProvider, useSnackbar };