import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Milestone from './Milestone/Milestone';
import Action from '../Action/Action';

const testEvents = [
  { id: 'bca', time: 'Jan', name: 'Apply to 30 companies', type: 'goal' },
  {
    id: 'cab',
    time: 'Jun',
    name: 'Get comfortable with reading docs',
    type: 'goal',
  },
  { id: 'abc', time: 'Oct', name: 'Get a new job', type: 'milestone' },
];

export default function Journey() {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ py: 3, px: 2, my: 3 }}>
        <Typography variant='h6'>Goal</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mr: 1 }}>
            Because I
          </Box>
          <TextField variant='standard' label='reason'></TextField>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              ml: { sm: 1 },
              mr: 1,
            }}
          >
            I'm going to
          </Box>
          <TextField variant='standard' label='do a specific thing'></TextField>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mx: 1 }}>in</Box>
          <TextField
            sx={{ width: 50, mr: 1 }}
            variant='standard'
            label='number'
          ></TextField>
          <TextField
            sx={{ width: 100 }}
            variant='standard'
            label='unit of time'
          ></TextField>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mx: 1 }}>by</Box>
          <TextField variant='standard' label='measurable action'></TextField>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography>
          What are two milestones that would mark significant progress on this
          journey?
        </Typography>
        <Milestone></Milestone>
        <Milestone></Milestone>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, my: 1 }}>
        <Typography sx={{ mt: 2 }}>
          What are some actions that can start to drive this journey forward?
        </Typography>
        <Action></Action>
        <Button>Add Action</Button>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant='body1' component='p'>
          Skill Meter
        </Typography>
        <LinearProgress
          sx={{ height: 25 }}
          variant='determinate'
          value={25}
        ></LinearProgress>
        <Typography variant='body1' component='p'>
          <em>Weekly decay w/o action 0.5%</em>
        </Typography>
        <Button
          sx={{
            my: 1,
          }}
          startIcon={<WarningIcon />}
        >
          Last action three days ago.
        </Button>

        <Typography textAlign={'center'}>A timeline of awesome...</Typography>
      </Paper>
    </Box>
  );
}