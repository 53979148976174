import * as React from 'react';
import { Link } from 'react-router-dom'

import SwipeableTemporaryDrawer from './SwipeableTemporaryDrawer';
import LightDarkModeToggle from '../LightDarkModeToggle'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function ButtonAppBar({ user, theme }) {
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={toggleDrawer('left', true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component={Link} to={ user ? '/' : '/login'} sx={{ flexGrow: 1, textDecoration: 'none', color: theme.palette.text.primary }}>
            YearWeGo
          </Typography>
          <LightDarkModeToggle theme={theme} />
        </Toolbar>
      </AppBar>
      <SwipeableTemporaryDrawer anchor='left' toggleDrawer={toggleDrawer} state={state} user={user} />
    </Box>
  );
}