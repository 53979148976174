import React from 'react';
import { Controller } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import useListenItems from '../../utils/hooks/useListenItems';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function MultiSelectCheckboxes({ control, name, collection }) {
  const items = useListenItems(collection);

  return items?.length > 0 ? (
    <Controller
      name={name.toLowerCase()}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          onChange={(_, data) => onChange(data)}
          value={value}
          multiple
          defaultValue={[]}
          id={`multi-select-checkboxes-${collection}`}
          options={items.map((item) => ({
            data: {
              name: item.data.name,
              model: item.data.model,
            },
            id: item.id,
          }))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableCloseOnSelect
          getOptionLabel={(option) => option.data.name}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                defaultValue={false}
              />
              { option.data.name ? option.data.name : `You didn't give this ${option.data.model} a name.` }
            </li>
          )}
          sx={{ mt: 1 }}
          renderInput={(params) => <TextField {...params} label={name} />}
        />
      )}
    ></Controller>
  ) : (
    <Typography sx={{ mt: 1 }} variant='p' component='p'>
      {`Loading ${collection}...`}
    </Typography>
  );
}