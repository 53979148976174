import React, { useState, useEffect, useContext, createContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import {
  handleAuthStateChange,
  handleLogin,
  handleSignout,
} from '../firebase';

import Typography from '@mui/material/Typography';

let AuthContext = createContext();

function AuthProvider({ children }) {
  let [loading, setLoading] = useState(true);
  let [user, setUser] = useState(null);

  useEffect(() => {
    handleAuthStateChange(setUser, setLoading);

    return () => setUser(null);
  }, []);

  let value = { loading, setLoading, user, setUser, handleLogin, handleSignout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

function AuthStatus() {
  let auth = useAuth();
  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }
  return (
    <Typography variant='p' component='p'>
      Welcome {auth.user}!
    </Typography>
  );
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.loading && !auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  } else if (auth.loading) {
    return 'Loading...'
  }

  return children;
}

export { AuthProvider, useAuth, AuthStatus, RequireAuth };