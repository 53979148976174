import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import PageviewIcon from '@mui/icons-material/Pageview';

import { deleteItem, incrementCounter } from '../../utils/firebase';
import { useSnackbar } from '../../utils/providers/snackbar';
import { useAuth } from '../../utils/providers/auth'

export default function BasicMenu({ collection, id, message }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const navigate = useNavigate();

  // const handleView = () => {
  //   navigate(`/${collection}/${id}`);
  // };

  // const handleEdit = () => {
  //   setAnchorEl(null);
  //   navigate(`/${collection}/${id}/edit`);
  //   dispatch({
  //     type: 'update',
  //     message: message ?? 'Item updated.',
  //     id: id ?? Math.floor(Math.random() * 10000000),
  //     severity: 'success',
  //   })
  // };

  const { dispatch } = useSnackbar();
  const { user } = useAuth()

  const handleDelete = async (collection, id, message) => {
    setAnchorEl(null);
    try {
      await Promise.all([
        await deleteItem(collection, id),
        await deleteItem('timeline', id),
      ])

      await Promise.all([
        await incrementCounter(collection, user.uid, false),
        await incrementCounter('timeline', user.uid, false)
      ])
      
      dispatch({
        type: 'delete',
        message: message ?? 'Item deleted.',
        id: id ?? Math.floor(Math.random() * 10000000),
        severity: 'warning',
      });
    } catch (error) {
      console.error(error)
      dispatch({
        type: 'firebaseError',
        message: error?.message ?? 'An unknown error occurred.',
        id: id ?? Math.floor(Math.random() * 10000000),
        severity: 'error',
      });
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        aria-label='settings'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          {/* <MenuItem onClick={handleView}>
            <ListItemIcon>
              <PageviewIcon />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem> */}
          {/* <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem> */}
          <MenuItem onClick={() => handleDelete(collection, id, message)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}