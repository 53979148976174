import React from 'react';

import Box from '@mui/material/Box';

import Chat from '../components/Chat/Chat';

export default function Reflect() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
    <Box sx={{ height: 300, width: '100%' }}>
      <Chat />
    </Box>
    </Box>
  );
}