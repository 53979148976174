import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Timeline from '../components/Timelines/Timeline/Timeline';
import SpeedDialTooltipOpen from '../components/Buttons/SpeedDialTooltipOpen';

import { useData } from '../utils/providers/data';
import { getCount } from '../utils/firebase';
import { useAuth } from '../utils/providers/auth';

export default function Act() {
  const { theme } = useOutletContext();
  const { dataState, dispatch, subscribeMoreItems } = useData();
  const { collections, loadCount } = dataState
  const { timeline } = collections;
  const { user } = useAuth()
  const [firebaseTimelineCount, setFirebaseTimelineCount] = useState()

  useEffect(() => {
    getCount('timeline', user.uid).then(res => setFirebaseTimelineCount(res))
  }, [user.uid]);

  const handleLoadMore = () => {
    const unsub = subscribeMoreItems('timeline');
    unsub.then(res => {
      dispatch({ type: 'addListener', listener: res, collection: 'timeline' });
    })
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        mt: 3,
      }}
    >
      <Timeline items={timeline} theme={theme} />
      { firebaseTimelineCount > loadCount.timeline &&
        <Button sx={{ mb: 5 }} variant='contained' onClick={() => handleLoadMore()}>
          load more
        </Button>
      }
      <Box sx={{ position: 'fixed', bottom: 65, right: 10 }}>
        <SpeedDialTooltipOpen></SpeedDialTooltipOpen>
      </Box>
    </Box>
  );
}