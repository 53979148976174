import React from 'react';
import { Link, Outlet, useOutletContext } from 'react-router-dom';
// import React, { useEffect, useMemo } from 'react';
// import { Link, Outlet, useOutletContext, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// import TrustScore from '../components/Models/TrustScore/TrustScore'
// import HomeTab from '../components/Nav/Tabs/HomeTab';
// import TabPanel from '../components/Nav/Tabs/TabPanel';

function Home() {
  const { theme, user } = useOutletContext();
  const [tabValue, setTabValue] = React.useState(0);
  // const loc = useLocation()

  // const tabLinks = useMemo(() => ['/connect', '/experience', '/achieve'], [])
  // const tabLabels = useMemo(() => ['Connect', 'Experience', 'Achieve'], [])

  // ensure that the tab highlight changes when navigating directly to a link
  // useEffect(() => {
  //   switch (loc.pathname) {
  //     case tabLinks[0]:
  //       setTabValue(0)
  //       break
  //     case tabLinks[1]:
  //       setTabValue(1)
  //       break
  //     case tabLinks[2]:
  //       setTabValue(2)
  //       break
  //     default:
  //       setTabValue(0)
  //   }
  // }, [tabLinks, loc.pathname]);

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue)
  // };

  return (
    user ?
    <Box
      sx={{
        py: 1,
      }}
    >
      {/* <Box sx={{ my: 2 }}>
        <TrustScore />
      </Box> */}
      <Container>
        {/* <HomeTab handleTabChange={handleTabChange} tabValue={tabValue} tabLinks={tabLinks} tabLabels={tabLabels}>
          <TabPanel value={tabValue} index={0}></TabPanel>
          <TabPanel value={tabValue} index={1}></TabPanel>
          <TabPanel value={tabValue} index={2}></TabPanel>
        </HomeTab> */}
        <Outlet context={{ theme }}/>
      </Container>
    </Box>
    :
    <Box sx={{ py: 5, textAlign: 'center' }}>
      <Container>
        <Typography component='h1' variant='h2'>YearWeGo</Typography>
        <Typography component='p' variant='subtitle1'>Do more of what matters.</Typography>
        <Box>
          <Button sx={{ mt: 5, mr: 1 }} variant='contained' component={Link} to='/signup'>Sign up</Button>
          <Button sx={{ mt: 5, ml: 1 }} variant='contained' component={Link} to='/login'>Sign in</Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;