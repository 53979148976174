import React from 'react';

import Box from '@mui/material/Box';

import TimelineDot from './TimelineDot';

export default function TimelineInfo({ theme }) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <TimelineDot theme={theme} />
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.grey[500]
            : theme.palette.grey[200]
        }
        borderRadius={25}
        height={25}
        width={100}
        fontSize={12}
        mb={1}
      >
        +3 companies
      </Box>
    </Box>
  );
}