import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://yearwego.com/">
        YearWeGo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function PasswordReset({ handlePasswordReset }) {
  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)

  const defaultValues = { email: '' }

  const { handleSubmit, reset, setValue, control } = useForm({ defaultValues });
  let navigate = useNavigate()

  const showAlertAndBackToLogin = () => {
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
    setTimeout(() => {
      navigate('/login')
    }, 5000)
  }

  const showErrorFn = () => {
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 5000)
  }

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      const { email } = data
      await handlePasswordReset(email)
      showAlertAndBackToLogin()
    } catch (error) {
      console.error(error)
      showErrorFn()
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <Grid container component="main" sx={{ height: '100vh', width: '100vw', }}>
      <CssBaseline />
      <Grid item xs={12} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Send Password Reset Email
          </Typography>
          
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)} sx={{ mt: 1 }}>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              required={{ required: 'Email required' }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Email
            </Button>

            <Grid 
              container
              direction="column"
              alignItems="end"
            >
              <Grid item xs>
                <Link href="login" variant="body2">
                  Back to login
                </Link>
              </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
              { showAlert && <Alert severity='info'>Password reset email sent!</Alert> }
              { showError && <Alert severity='error'>Wrong email!</Alert> }
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
          
        </Box>
      </Grid>
    </Grid>
  );
}