import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function TimelineDot({ theme }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1, height: 40, width: 100, borderRadius: '25px', backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300]}}>
            <Typography variant='body2'>August 2022</Typography>
        </Box>
    )
}