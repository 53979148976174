import { useState, useEffect, useCallback } from 'react';

import {
  db,
  query,
  collection,
  limit,
  onSnapshot,
  orderBy,
  where,
  startAfter,
} from '../firebase';

import { useAuth } from '../providers/auth';

export default function useListenItems(collectionName) {
  const [items, setItems] = useState([]);
  const [lastVisible, setLastVisible] = useState();

  const { user } = useAuth();

  const listenToCollection = useCallback(() => {
    const q = query(
      collection(db, collectionName),
      where('owner', '==', user?.uid ?? ''),
      orderBy('createdAt', 'desc'),
      limit(2),
    );
    const unsub = onSnapshot(q, (snapshot) => {
      const docs = []
      const raw = []
      snapshot.forEach((doc) => {
        raw.push(doc)
        docs.push({
            data: doc.data(),
            id: doc.id,
        })
      })
      setItems(docs)
      if (raw?.length > 0) {
        setLastVisible(raw[raw?.length - 1])
      }
    },
    (error) => {
        console.log(error)
    });
    return unsub;
  }, [user, collectionName]);

  useEffect(() => {
    const unsub = listenToCollection();

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return items;
}