import React, { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';

import Message from './Message/Message';

const testMessages = [
  { text: 'How are you?', sender: 'Future You', left: true },
  { text: "I'm good", sender: 'You', left: false },
  { text: "That's good to hear.", sender: 'Future You', left: true },
  { text: 'How are you?', sender: 'Future You', left: true },
  { text: "I'm good", sender: 'You', left: false },
  { text: "That's good to hear.", sender: 'Future You', left: true },
  { text: 'How are you?', sender: 'Future You', left: true },
  { text: "I'm good", sender: 'You', left: false },
  { text: "That's good to hear.", sender: 'Future You', left: true },
];

export default function Chat() {
  const { theme } = useOutletContext();
  const msgEndRef = useRef(null)

  const scrollToBottom = () => {
    msgEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, []); // this will need a dependency when using real messages

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Avatar />
      <Typography
        sx={{
          textAlign: 'center',
          width: '100%',
          mt: 1,
          pb: 1,
          borderBottom: `1px dotted ${theme.palette.info.light}`,
        }}
        variant='h6'
        component='p'
      >
        Future You
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 500,
          width: '100%',
          overflow: 'auto',
          pt: 1,
        }}
      >
        {testMessages.map((msg, i) => (
          <Message message={msg.text} side={msg.left} key={i} />
        ))}
        <Box ref={msgEndRef}></Box>
        <Box
          sx={{
            position: 'sticky',
            backdropFilter: 'blur(5px)',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            p: 1,
            mt: 3,
          }}
        >
          <TextField fullWidth size='small' label='Type something...' />
          <Button
            sx={{ border: `1px solid ${theme.palette.info.main}`, ml: 1 }}
          >
            <SendIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}