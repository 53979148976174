import React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import Journeys from './Journey/Journeys';

export default function Plan() {
    return (
        <Box>
            <Typography variant='body1' component='p'>Projected Energy</Typography>
            <LinearProgress sx={{ height: 25 }} variant='determinate' value={75}></LinearProgress>
            <Journeys/>
        </Box>
    )
}