import { useReducer } from 'react';

import { useAuth } from '../providers/auth';
import { addItem, setItem, serverTimestamp, incrementCounter } from '../firebase';

export default function useAddRole() {
  const { user } = useAuth();
  const initialState = {
    owner: user.uid,
    createdAt: serverTimestamp(),
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function getDefaultState(collection) {
    switch(collection) {
        case 'roles':
            return {
                model: 'role',
                collection: 'roles',
                name: '',
                notes: '',
                company: '',
                contacts: [],
                actions: [],
                location: '',
                salary: 0,
                status: 'Interested',
                url: '',
                ...initialState,
            }
        case 'notes':
            return {
                model: 'note',
                collection: 'notes',
                contacts: [],
                name: '',
                notes: '',
                ...initialState,
            }
        case 'actions':
            return {
                model: 'action',
                collection: 'actions',
                contacts: [],
                name: '',
                notes: '',
                status: 'Done',
                difficulty: '',
                ...initialState,
            };
        case 'contacts':
            let contactDefaults = {
                model: 'contact',
                collection: 'contacts',
                actions: [],
                contacts: [],
                firstName: '',
                lastName: '',
                jobTitle: '',
                companies: [],
                location: '',
                emails: [],
                phones: [],
                socialLinks: {
                    twitter: '',
                    facebook: '',
                    linkedIn: '',
                    github: '',
                },
                notes: '',
                lastSaw:'',
                lastSpoke: '',
                accountabilityPartner: false,
                ...initialState,
            }
            return {...contactDefaults, name: `${contactDefaults.firstName} ${contactDefaults.lastName}`}
        default:
    }
  }

  function reducer(state, action) {
    let defaultState = getDefaultState(action.collection)
    switch (action.type) {
      case 'add':
        addItem(action.collection, defaultState)
          .then((res) => {
            incrementCounter(action.collection, user.uid)
            setItem('timeline', res.id, defaultState)
            .then((res) => {
              incrementCounter('timeline', user.uid)
            })
            .catch((err) => {
              console.error(err);
            });    
          })
          .catch((err) => {
            console.error(err);
          });
        break;
      default:
    }
    return defaultState;
  }

  function handleAddItem(handleClose, collection) {
    try {
      dispatch({ type: 'add', collection });
      handleClose();
    } catch (err) {
      console.error(err);
    }
  }

  return { state, dispatch, handleAddItem };
}