import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import WorkIcon from '@mui/icons-material/Work';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';

import Menu from '../Nav/Menu';

import FormFields from '../Forms/FormFields';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Box {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  paddingTop: 5,
  marginTop: 1,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Card({ item, collection, theme }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getDeleteMessage = () => {
    return `You deleted ${
      item?.data?.name ? `"${item?.data?.name}"` : `a ${item?.data?.model}`
    }.`;
  };

  const getCardStyles = () => {
    if (collection === 'roles') {
      return {
        icon: <WorkIcon />,
        bgColor: theme.palette.primary.role,
        borderColor: '#00AA8B',
        avatarColor: '#008FD6',
      };
    } else if (collection === 'actions') {
      return {
        icon: <AddTaskIcon />,
        bgColor: theme.palette.primary.action,
        borderColor: '#00AA8B',
        avatarColor: '#008FD6',
      };
    } else if (collection === 'notes') {
      return {
        icon: <NoteAddIcon />,
        bgColor: theme.palette.primary.note,
        borderColor: '#EA6B50',
        avatarColor: '#008FD6',
      };
    } else if (collection === 'contacts') {
      return {
        icon: <PersonAddIcon />,
        bgColor: theme.palette.primary.contact,
        borderColor: '#AB2476',
        avatarColor: '#008FD6',
      };
    }
  };

  const [cardStyles] = useState(getCardStyles());

  return (
    <MuiCard
      sx={{
        position: 'relative',
        pt: 3,
        my: 2,
        width: 350,
        borderRadius: 5,
        backgroundColor: cardStyles.bgColor,
      }}
    >
      <Box sx={{ position: 'absolute', top: 0, right: 5 }}>
        <Menu
          aria-label='settings'
          collection={collection}
          id={item.id}
          message={getDeleteMessage()}
        />
      </Box>
      <CardHeader
        avatar={
          <Avatar
            sx={{ backgroundColor: cardStyles.avatarColor }}
            aria-label={item.data.model}
          >
            {cardStyles.icon}
          </Avatar>
        }
        title={
          item.data.name ? `${item.data.name}` : `Click 'EDIT' to update card`
        }
        subheader={`Created on ${item?.data?.createdAt?.toDate()}`}
      ></CardHeader>
      <CardContent>
        <Typography>{item.data.notes}</Typography>
        <Typography>{item.id}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          size='small'
          color='secondary'
          onClick={handleExpandClick}
          sx={{ mx: 'auto' }}
        >
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
          Edit
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <FormFields item={item} collection={collection} />
        </CardContent>
      </Collapse>
    </MuiCard>
  );
}