import React from 'react';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';


export default function Action() {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <TextField fullWidth margin='normal' variant='filled' label='Do this'></TextField>
            <TextField fullWidth margin='normal' variant='filled' label='so that benefit'></TextField>
        </Box>
    )
}