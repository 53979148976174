import * as React from 'react';
import { Link, NavLink } from 'react-router-dom'

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ForestIcon from '@mui/icons-material/Forest';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Divider from '@mui/material/Divider';

import { useAuth } from '../../utils/providers/auth';

export default function SwipeableTemporaryDrawer({ anchor, toggleDrawer, state, user }) {
  const { handleSignout, setUser, setLoading } = useAuth()
  const protectedLinks = [
    { 
      name: 'Act',
      path: '/act',
      icon: <DoneAllIcon/>,
    },
    { 
      name: 'Reflect',
      path: '/reflect',
      icon: <PsychologyIcon/>,
    },
    { 
      name: 'Plan',
      path: '/plan',
      icon: <ForestIcon/>,
    },
  ]

  const publicLinks = [
    { 
      name: 'Home',
      path: '/',
      icon: <HomeIcon/>,
    },
    {
      name: 'Sign up',
      path: '/signup',
      icon: <PersonAddIcon/>,
    },

  ]

  const pickLinks = () => {
    return user ? protectedLinks : publicLinks
  }

  let activeStyle = {
    textDecoration: "underline",
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pickLinks().map((link, index) => (
          <ListItem button key={link.name} component={NavLink} to={link.path} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.name} />
          </ListItem>
        ))}
      </List>
      <Divider></Divider>
      { user ?
        <ListItem onClick={() => handleSignout(setUser, setLoading)} button key='logout' component={Link} to='/login' color='inherit'>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
        <ListItemText primary='Log out' />
        </ListItem>
        :
        <ListItem button key='login' component={Link} to='/login' color='inherit'>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
        <ListItemText primary='Sign in' />
        </ListItem>
      }
    </Box>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}