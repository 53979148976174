import React from 'react';

import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SimpleSnackbar({ handleClick, handleClose, message, open, id, severity }) {
  const action = (
    <>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box>
      <Snackbar
        key={`${id}-${message}`}
        open={open}
        autoHideDuration={6000}
        action={action}
        onClose={handleClose}
        sx={{ mb: 5 }}
      >
        <Alert onClose={handleClose} severity={severity ?? 'info'}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}