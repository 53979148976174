import React from 'react';
import { Outlet } from 'react-router-dom';

import ButtonAppBar from './components/Nav/ButtonAppBar';
import BottomNav from './components/Nav/BottomNav';

import './App.css';

import { useAuth } from './utils/providers/auth.js'
import { useSnackbar } from './utils/providers/snackbar';
import { useCustomTheme } from './utils/providers/theme';
import SimpleSnackbar from './components/Feedback/SimpleSnackbar';

function App() {
  let auth = useAuth();
  const { user } = auth;
  const { theme } = useCustomTheme();

  const { snackbarState, handleSnackbarClick, handleSnackbarClose } = useSnackbar()

  return (
    <div className='app'>
      <ButtonAppBar
        user={user}
        theme={theme}
      />
      <Outlet context={{ user, theme }} />
      <SimpleSnackbar
        handleClick={handleSnackbarClick}
        handleClose={handleSnackbarClose}
        message={snackbarState?.message}
        open={snackbarState?.open}
        id={snackbarState?.id}
        severity={snackbarState?.severity}
      />
      <BottomNav user={user} />
    </div>
  );
}

export default App;