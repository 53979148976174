import React, { useEffect, useCallback, useMemo } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { debounce } from 'lodash';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { updateItem } from '../../../utils/firebase';
import MultiSelectCheckboxes from '../../Select/MultiSelectCheckboxes';

export default function FormAutoSaveNote({ item, collection }) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm();
  const { id } = item;

  useEffect(() => {
    reset(item?.data);
  }, [item, reset]);

  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (isDirty) {
          delete data.createdAt;
          await updateItem(collection, id, data);
          await updateItem('timeline', id, data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [collection, id, isDirty]
  );

  const data = useWatch({ control });

  // https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedSubmit = useMemo(() => debounce(onSubmit, 1000), [onSubmit]);
  
  useEffect(() => {
    debouncedSubmit(data);

    return () => {
      debouncedSubmit.cancel();
    };
  }, [data, debouncedSubmit]);

  

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{ mt: 1 }}
    >
      <Controller
        name='name'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              id='name'
              label='Name'
              placeholder={'Some feedback I learned...'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <Controller
        name='notes'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              multiline
              maxRows={2}
              id='notes'
              label='Notes'
              placeholder={'Never give up.'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <MultiSelectCheckboxes control={control} name='Contacts' collection='contacts' />
    </Box>
  );
}