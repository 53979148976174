// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  deleteDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  orderBy,
  getDoc,
  getDocs,
  limit,
  query,
  where,
  initializeFirestore,
  serverTimestamp,
  startAt,
  startAfter,
  endBefore,
  endAt,
  writeBatch,
  increment,
} from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  signOut,
} from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAR27GMDhfeD3TuPcelMSSKAfjtrekhRPc",
    authDomain: "yearwego.firebaseapp.com",
    projectId: "yearwego",
    storageBucket: "yearwego.appspot.com",
    messagingSenderId: "63441705189",
    appId: "1:63441705189:web:561b0fbbde4cc38b05dc0c",
    measurementId: "G-74ZFECQGTP"
};
  

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
initializeFirestore(firebase, {
  ignoreUndefinedProperties: true,
});
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);

const COLLECTIONS = ['timeline', 'actions', 'contacts', 'roles', 'notes'];

async function handleSignup(email, password, firstName, lastName) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const { user } = userCredential;
    await updateProfile(user, {
      displayName: firstName,
    });
    const payload = {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      fullName: `${firstName} ${lastName}`,
      email: user.email,
      uid: user.uid,
      createdAt: serverTimestamp(),
    };
    const ref = await createUser(user, payload);
    // run creation of counters for each collection in parallel
    await Promise.all(COLLECTIONS.map(async (collection) => await createCounter(collection, user.uid)))
    return ref;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorCode, errorMessage);
    throw error;
  }
}

async function handleLogin(email, password, setUser, setLoading) {
  try {
    setLoading(true);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    setUser(userCredential.user);
    return userCredential.user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorCode, errorMessage);
    throw error;
  } finally {
    setLoading(false);
  }
}

async function handleSignout(setUser, setLoading) {
  setLoading(true);
  try {
    await signOut(auth);
    // Sign-out successful.
    setUser(null);
    console.log('User signed out');
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

async function handlePasswordReset(email) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function handleAuthStateChange(setUser, setLoading) {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
    } else {
      // User is signed out
      setUser(false);
    }

    setLoading(false);
  });
}

async function createUser(user, payload) {
  try {
    const { uid } = user;
    let docRef = doc(firestore, 'users', uid);
    await setDoc(docRef, payload);
    return docRef;
  } catch (error) {
    throw error;
  }
}

async function updateUser(user, payload) {
  try {
    const { uid } = user;
    const docRef = doc(firestore, 'users', uid);
    const updateRef = await updateDoc(docRef, payload);
    return updateRef;
  } catch (error) {
    throw error;
  }
}

function getCurrentUser() {
  const user = auth.currentUser;

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // ...
  } else {
    // No user is signed in.
  }
  return user;
}

/** Add an item to Firestore */
async function addItem(colRef, payload) {
  try {
    const docRef = await addDoc(collection(firestore, colRef), payload);
    return docRef;
  } catch (error) {
    throw error;
  }
}

async function setItem(colRef, docId, payload) {
  try {
    const docRef = await setDoc(doc(firestore, colRef, docId), payload, {
      merge: true,
    });
    return docRef;
  } catch (error) {
    throw error;
  }
}

async function updateItem(collection, id, payload) {
  try {
    const docRef = doc(firestore, collection, id);
    return await updateDoc(docRef, payload);
  } catch (error) {
    throw error;
  }
}

function listenToItem(id, colRef, setItem) {
  const unsub = onSnapshot(doc(firestore, colRef, id), (doc) => {
    const item = doc.data();
    setItem(item);
  });
  return unsub;
}

async function getItem(collection, id) {
  const docRef = doc(firestore, collection, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    throw new Error('No such document!');
  }
}

async function deleteItem(collection, id) {
  await deleteDoc(doc(firestore, collection, id));
}

// https://firebase.google.com/docs/firestore/solutions/counters
// The following code initializes a distributed counter:
async function createCounter(collectionName, uid, num_shards = 5) {
  try {
    // get a new write batch
    const batch = writeBatch(firestore);

    let counterDocRef = doc(firestore, 'users', `${uid}`, 'counters', `${collectionName}`)

    // Initialize the counter document
    batch.set(counterDocRef, {
      num_shards: num_shards,
      owner: uid,
      createdAt: serverTimestamp(),
    });

    // Initialize each shard with count=0
    for (let i = 0; i < num_shards; i++) {
      const shardRef = doc(firestore, 'users', `${uid}`, 'counters', `${collectionName}`, 'shards', `${i.toString()}`);
      batch.set(shardRef, {
        count: 0,
        owner: uid,
        createdAt: serverTimestamp(),
      });
    }

    // Commit the write batch
    return await batch.commit();
  } catch (error) {
    console.error(error);
  }
}

// To increment the counter, choose a random shard and increment the count:
async function incrementCounter(collectionName, uid, increase = true, num_shards = 5) {
  try {
    // Select a shard of the counter at random
    const shardId = Math.floor(Math.random() * num_shards).toString();
    const shardRef = doc(firestore, 'users', `${uid}`, 'counters', `${collectionName}`, 'shards', shardId);

    // Update count
    return await updateDoc(shardRef, {
      count: increase ? increment(1) : increment(-1),
      lastModified: serverTimestamp(),
    });
  } catch (error) {
    console.error(error);
  }
}

// To get the total count, query for all shards and sum their count fields:
async function getCount(collectionName, uid) {
  try {
    // Sum the count of each shard in the subcollection
    const snapshot = await getDocs(collection(firestore, `users/${uid}/counters/${collectionName}/shards`));
    let totalCount = 0;
    snapshot.forEach((doc) => {
      totalCount += doc.data().count;
    });

    return totalCount;
  } catch (error) {
    console.error(error);
  }
}

export {
  firebase,
  firestore as db,
  COLLECTIONS,
  handleSignup,
  handleLogin,
  handleSignout,
  handlePasswordReset,
  handleAuthStateChange,
  getCurrentUser,
  createUser,
  updateUser,
  listenToItem,
  deleteItem,
  query,
  collection,
  onSnapshot,
  orderBy,
  limit,
  where,
  updateItem,
  getItem,
  addItem,
  setItem,
  doc,
  serverTimestamp,
  startAt,
  startAfter,
  endBefore,
  endAt,
  getDoc,
  getDocs,
  writeBatch,
  createCounter,
  incrementCounter,
  getCount,
};