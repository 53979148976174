import React from 'react';
import { useOutletContext } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Message({ message, side }) {
  const { theme } = useOutletContext();
  return side ? (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        p: 2,
        my: 1,
        alignSelf: 'start',
        borderRadius: '25px',
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        p: 2,
        my: 1,
        alignSelf: 'end',
        borderRadius: '25px',
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
}