import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WorkIcon from '@mui/icons-material/Work';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import useAddItem from '../../utils/hooks/useAddItem';

export default function SpeedDialTooltipOpen() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { handleAddItem } = useAddItem()

  const actions = [
    {
      icon: <NoteAddIcon />,
      name: 'note',
      handler: () => handleAddItem(handleClose, 'notes'),
    },
    { 
      icon: <PersonAddIcon />,
      name: 'contact',
      handler: () => handleAddItem(handleClose, 'contacts'),
    },
    {
      icon: <WorkIcon />,
      name: 'role',
      handler: () => handleAddItem(handleClose, 'roles'),
    },
    {
      icon: <AddTaskIcon />,
      name: 'action',
      handler: () => handleAddItem(handleClose, 'actions'),
    },
  ];

  return (
    <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Speed dial with tooltips"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.handler}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}