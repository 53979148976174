import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ForestIcon from '@mui/icons-material/Forest';
// import LuggageIcon from '@mui/icons-material/Luggage';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Paper from '@mui/material/Paper';

export default function BottomNav({ user }) {
  const [value, setValue] = useState(0);
  const ref = useRef(null);

  const loc = useLocation()

  // ensure bottom nav highlight changes
  useEffect(() => {
    switch (loc.pathname) {
      case '/':
        setValue(0)
        break
      case '/reflect':
        setValue(1)
        break
      case '/plan':
        setValue(2)
        break
      default:
        setValue(0)
    }
  }, [loc.pathname]);

  return (
    <Box sx={{ pt: 7, display: { sm: 'none' } }} ref={ref}>
      <CssBaseline />
     
      <Paper sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1000 }} elevation={3}>
        {
          user ?
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction label="Act" icon={<DoneAllIcon />} component={Link} to={'/'}/>
            <BottomNavigationAction label="Reflect" icon={<PsychologyIcon />} component={Link} to={'/reflect'}/>
            <BottomNavigationAction label="Plan" icon={<ForestIcon />} component={Link} to={'/plan'}/>
            {/* <BottomNavigationAction label="Contacts" icon={<ContactsIcon />} component={Link} to={'/contacts'}/>
            <BottomNavigationAction label="Trips" icon={<LuggageIcon />} component={Link} to={'/trips'}/> */}
          </BottomNavigation>
            :
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to={'/'}/>
              <BottomNavigationAction label="Sign up" icon={<PersonAddIcon />} component={Link} to={'/signup'}/>
              <BottomNavigationAction label="Sign in" icon={<LoginIcon />} component={Link} to={'/login'}/>
            </BottomNavigation>
        }
      </Paper>
    </Box>
  );
}