import React, { useEffect, useCallback, useMemo } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { debounce } from 'lodash';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import LaunchIcon from '@mui/icons-material/Launch';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';

import { updateItem } from '../../../utils/firebase';
import MultiSelectCheckboxes from '../../Select/MultiSelectCheckboxes';

export default function FormAutoSaveRole({ item, collection }) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm({ mode: 'onBlur' });
  const { id } = item;

  useEffect(() => {
    reset(item?.data); // load data from Firestore the first time this form appears
  }, [item, reset]);

  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (isDirty) {
          delete data.createdAt;
          await updateItem(collection, id, data);
          await updateItem('timeline', id, data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [collection, id, isDirty]
  );

  const data = useWatch({ control });

  // https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedSubmit = useMemo(() => debounce(onSubmit, 1000), [onSubmit]);

  useEffect(() => {
    debouncedSubmit(data);

    return () => {
      debouncedSubmit.cancel();
    };
  }, [data, debouncedSubmit]);

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{ mt: 1 }}
    >
      <Controller
        name='name'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              id='name'
              label='Name'
              placeholder={'Software Engineer'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <Controller
        name='company'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              id='company'
              label='Company'
              placeholder={'Google'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <Controller
        name='status'
        control={control}
        defaultValue=''
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id='select-label-status'>Status</InputLabel>
            <Select
              labelId='select-label-status'
              id='status'
              label='Status'
              placeholder='Pick status'
              type='select'
              {...field}
            >
              <MenuItem value={'Interested'}>Interested</MenuItem>
              <MenuItem value={'Reading job description'}>
                Reading job description
              </MenuItem>
              <MenuItem value={'Determining if qualified'}>
                Determining if qualified
              </MenuItem>
              <MenuItem value={'Researching company'}>
                Researching company
              </MenuItem>
              <MenuItem value={'Optimizing resume'}>Optimizing resume</MenuItem>
              <MenuItem value={'Applied'}>Applied</MenuItem>
              <MenuItem value={'In contact'}>In contact</MenuItem>
              <MenuItem value={'Interviewing'}>Interviewing</MenuItem>
              <MenuItem value={'Accepted offer!'}>Accepted offer!</MenuItem>
              <MenuItem value={'Passed on opportunity'}>
                Passed on opportunity
              </MenuItem>
              <MenuItem value={'No offer extended'}>No offer extended</MenuItem>
              <MenuItem value={'Not available'}>Not available</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name='notes'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              multiline
              maxRows={2}
              id='notes'
              label='Notes'
              placeholder={'The company looks interesting because...'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <Controller
        name='location'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              id='location'
              label='Location'
              placeholder={'Remote'}
              type='text'
              value={value}
              onChange={onChange}
            />
          );
        }}
      />

      <Controller
        name='salary'
        control={control}
        defaultValue={null}
        render={({ field }) => {
          return (
            <NumericFormat
              control={control}
              value={field.value}
              onValueChange={(change) => {
                field.onChange(change.value);
              }}
              customInput={TextField}
              prefix={'$'}
              // decimalScale={2}
              allowEmptyFormatting={true}
              fixedDecimalScale={true}
              thousandSeparator={true}
              type='text'
              {...field}
            />
          );
        }}
      />

      <Controller
        name='url'
        control={control}
        defaultValue=''
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              margin='normal'
              fullWidth
              id='url'
              label='URL'
              placeholder={'google.com'}
              type='text'
              value={value}
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>https://</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <a
                      href={`https://${value}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IconButton>
                        <LaunchIcon></LaunchIcon>
                      </IconButton>
                    </a>
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />

      <MultiSelectCheckboxes
        control={control}
        name='Contacts'
        collection='contacts'
      />
    </Box>
  );
}