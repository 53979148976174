import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { useAuth } from '../utils/providers/auth';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://yearwego.com/">
        YearWeGo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInSide() {
  const [showError, setShowError] = useState(false)

  let auth = useAuth()
  const { setLoading, setUser, handleLogin } = auth

  const defaultValues = {
    email: '',
    password: '',
    rememberMe: false,
  }

  const { handleSubmit, reset, setValue, control } = useForm({ defaultValues });
  let navigate = useNavigate()
  let location = useLocation()

  let from = location.state?.from?.pathname || "/";

  const showErrorFn = () => {
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 5000)
  }

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      const { email, password } = data
      await handleLogin(email, password, setUser, setLoading)
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true })
    } catch (error) {
      showErrorFn()
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <Grid container component="main" sx={{ height: '100vh', width: '100vw', }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random?topic=travel)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit, onError)} sx={{ mt: 1 }}>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              required={{ required: 'Email required' }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
            <Controller
              name='password'
              control={control}
              defaultValue=''
              required={{ required: 'Password required' }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
            {/* <Controller 
              name='rememberMe'
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value }}) => {
                return (
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="reset-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              { showError && <Alert severity='error'>Wrong email or password!</Alert> }
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
          
        </Box>
      </Grid>
    </Grid>
  );
}